html {
  overflow: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  padding: 0;
  margin: 0;
  background: black url('bg.jpg');
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
}

.root {
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-top: 10vmin;
  width: 100vw;
  height: 100vh;  
}

.text-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5vmin;
  border-radius: 2vmin;
  background: rgba(255, 255, 255, 0.8);
}

.text {
  font-weight: bold;
  color: black;
  display: flex;    
  font-size: 5vmin;  
}

.text.small {
  font-size: 4vmin;
}